import React from 'react';
import css from '@emotion/css';
import { colors, borderRadius, spacing, fontSize } from '@styles/theme';
import { Typo } from '../../core/typo/Typo';
import { Icon } from '@components/core/icon';

interface GatekeepProps {
    active: boolean;
}

export const Gatekeep: React.FC<GatekeepProps> = ({ active }) => {
    if (!active) {
        return null;
    }

    return (
        <div
            css={css`
                position: absolute;
                z-index: 100;
                overflow: hidden;

                width: 100%;
                height: 100%;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    z-index: 100;

                    width: 100%;
                    height: 100%;

                    border-radius: ${borderRadius('sm')};
                    cursor: not-allowed;

                    :hover {
                        & > div {
                            transform: translate3d(0, 0, 0);
                        }
                    }
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        top: ${spacing(2)};
                        right: ${spacing(2)};
                        color: ${colors('gray', 100)};
                        transition: all ease-in-out 200ms;

                        transform: translate3d(calc(100% - 1.25rem), 0, 0);
                    `}
                >
                    <Icon
                        id="lock"
                        css={css`
                            margin-right: ${spacing(2)};
                        `}
                    ></Icon>
                    <Typo variant="label">Bientôt disponible</Typo>
                </div>
            </div>
        </div>
    );
};

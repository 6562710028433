// Modules
import React, { Fragment } from 'react';
import { Link } from 'gatsby';

// Models
import { WorkshopInterface } from '@models/Workshop';

// Components
import { Card, CardImage, CardContent, CardBody, CardTitle, CardFooter } from '@components/core/card';
import { Icon } from '@components/core/icon';
import { Gatekeep } from '@components/home/gatekeep/Gatekeep';
import css from '@emotion/css';

interface WorkshopCardInterface {
    tags: string[];
}

export const WorkshopCard: React.FC<WorkshopCardInterface & WorkshopInterface> = ({
    id,
    date,
    path,
    title,
    image,
    tags,
}) => {
    const today = new Date();
    const released = date < today;

    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Gatekeep active={!released} />
            <Link key={id} to={path}>
                <Card hover={released}>
                    <CardImage alt={title} image={image} />
                    <CardContent>
                        <CardBody>
                            <CardTitle>{title}</CardTitle>
                        </CardBody>
                        {/*                    <CardFooter>
                        {tags
                            .filter((tag, index) => index < 2)
                            .map(tag => (
                                <Icon key={tag} id={tag} colored />
                            ))}
                    </CardFooter> */}
                    </CardContent>
                </Card>
            </Link>
        </div>
    );
};
